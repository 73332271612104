@import "@/assets/scss/var.scss";
.after {
    &-header {
        padding: 10px;
        margin: 0;
        padding-bottom: 0;
        /deep/ header {
            .header-num {
                text-align: center;
            }
            .header-commodity {
                flex: 3;
            }
        }
    }

    &-item {
        padding: 10px;
        margin: 10px 0 10px 0;

        background: #fff;

        /deep/ .row {
            .item__num {
                text-align: center;
            }
            .item__left {
                flex: 3;
                &-img:first-child {
                    margin-left: 30px;
                }
            }
        }
    }
    .bottom-nav {
        z-index: 1;
        bottom: 0;
        border-right: 0;
        position: sticky;
        left: 0;
        right: 0;
        &_content {
            font-size: $fontsize;
            height: 60px;
            padding: 0 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 960px;
            width: 1000px;
            background: #fff;
            // border: 1px solid #f0f0f0;
            margin: auto;
            box-sizing: border-box;
            // box-shadow: 0 -1px 8px rgba(0, 1, 1, 0.08);
        }
        &__right {
            &-button {
                width: 100px;
            }
        }
    }

}
